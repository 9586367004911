import {
  Component,
  OnDestroy,
  OnInit,
  ErrorHandler,
  NgZone,
  computed,
  HostListener,
} from '@angular/core';
import {filter, map, mergeMap, Subscription, take, throttleTime, withLatestFrom} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {registerLocaleData} from '@angular/common';
import localeDanish from '@angular/common/locales/da';
import localeGerman from '@angular/common/locales/de';
import {LocaleService} from './core/services/locale/locale.service';
import {SubscriptionService} from './core/services/subscription/subscription.service';
import {App} from '@capacitor/app';
import {AuthService} from '@auth0/auth0-angular';
import {callbackUri} from './auth.config';
import {Browser} from '@capacitor/browser';
import {ProfileService} from "./core/services/profile/profile.service";
import { EUserType } from './core/services/profile/user-type.enum';
import {AppUpdateService} from "./core/services/update-version/app-update.service";
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { LoadingService } from './core/services/loading/loading.service';
import { Capacitor } from '@capacitor/core';
import { CookieConsentService } from './core/services/cookie-consent/cookie-consent.service';
import { CookieConsent } from './core/services/cookie-consent/cookie-consent.data';
import { LogoutService } from './core/services/logout/logout.service';

const SMALL_WIDTH_BREAKPOINT = 480;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isScreenSmall = false;

  isLoading = toSignal(this.loadingService.loading$, { initialValue: false });

  cookieConsent: CookieConsent | null = null;

  private subscription = this.subscriptionService.subscription;
  private isActiveSubscription = computed(() => {
    if (this.subscription() && this.subscription()!.activeSubscription) {
      return true;
    }
    return false;
  });

  currentProfile = this.profileService.currentProfile;
  theme = this.profileService.theme;
  isChildProfile = computed(() => {
    let isChildProfile = false;

    if (this.currentProfile() && (this.currentProfile()!.userType === EUserType.Kid || this.currentProfile()!.userType === EUserType.Tween)) {
      isChildProfile = true;
    }
    return isChildProfile;
  });

  private breakpointSub!: Subscription;
  private routingEventSub!: Subscription;
  private cookieConsentSub!: Subscription;

  routerData$ = this.router.events.pipe(
    filter(e => e instanceof ActivationEnd),
    throttleTime(0),
    map((e: ActivationEnd) => e.snapshot.data)
  );
  urlAfterRedirects$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map((navEnd: NavigationEnd) => navEnd.urlAfterRedirects)
  );
  hideToolbar = toSignal(this.urlAfterRedirects$.pipe(
    withLatestFrom(this.routerData$),
    map((data) => {
      let hideToolbar = false;
      const routeData = data[1]['hideToolbar'];
      if (routeData) {
        hideToolbar = routeData as boolean;
      }
      return hideToolbar;
    })
  ));

  showToolbar = computed(() => {
    return this.isActiveSubscription() && this.currentProfile() && !this.isChildProfile() && !this.hideToolbar();
  });

  constructor(
    private errorHandler: ErrorHandler,
    public dialog: MatDialog,
    private profileService: ProfileService,
    private breakpointObserver: BreakpointObserver,
    private localeService: LocaleService,
    private subscriptionService: SubscriptionService,
    private ngZone: NgZone,
    private authService: AuthService,
    private appUpdateService: AppUpdateService,
    private router: Router,
    private loadingService: LoadingService,
    private cookieConsentService: CookieConsentService,
    private logoutService: LogoutService
  ) {
    this.profileService.initNotifications();
    
    //subscribes every changes of your route
    this.routingEventSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd){
        //scroll to top
        window.scrollTo(0,0);
      }
    });
  }

  ngOnInit() {

    // Handle callback from login
    this.handleLoginCallback();

    // get screen size
    this.breakpointSub = this.breakpointObserver.observe([`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`]).subscribe({
      next: (state: BreakpointState) => {
        this.isScreenSmall = state.matches;
      },
      error: (error) => this.errorHandler.handleError(error)
    });

    // Register locale data since only the en-US locale data comes with Angular
    registerLocaleData(localeDanish);
    registerLocaleData(localeGerman);
    this.localeService.setupLanguages();

    // Register subscription language
    this.subscriptionService.getSubscription().pipe(
      take(1)
    ).subscribe({
      next: (subscription) => {
        if (subscription) {
          this.localeService.setLanguage(subscription.language);
        }
      }
    });

    this.getCookieConsent();

    // if on mobile platform
    if (Capacitor.getPlatform() != 'web') {

      // check for app updates
      this.appUpdateService.checkForUpdatesAndForce();

      // Check notification permissions
      if (this.currentProfile()) {
    
        this.profileService.checkProfileNotificationPermission(this.currentProfile()!);
      }
    }
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this.breakpointSub.unsubscribe();
    this.routingEventSub.unsubscribe();
    this.cookieConsentSub.unsubscribe();

    if (!this.cookieConsent || this.cookieConsent.functionalCookies === false) {
      this.logoutService.logout();
    }
  }

  getCookieConsent() {
    // check if the device has given consent
    this.cookieConsentSub = this.cookieConsentService.cookieConsent$.subscribe({
      next: (cookieConsent) => {
        this.cookieConsent = cookieConsent;
        if (!cookieConsent) {
          this.router.navigate(['/consent']);
        }
      }
    });
  }


  private handleLoginCallback() {
    // Use Capacitor's App plugin to subscribe to the `appUrlOpen` event
    App.addListener('appUrlOpen', ({url}) => {
      // Must run inside an NgZone for Angular to pick up the changes
      // https://capacitorjs.com/docs/guides/angular
      this.ngZone.run(() => {
        if (url?.startsWith(callbackUri)) {
          // If the URL is an authentication callback URL..
          if (
            url.includes('state=') &&
            (url.includes('error=') || url.includes('code='))
          ) {
            // Call handleRedirectCallback and close the browser
            this.authService.handleRedirectCallback(url).pipe(
              mergeMap(() => Browser.close())
            ).subscribe(next => {
              window.location.reload();
            });
          } else {
            Browser.close();
          }
        }
      });
    });
  }
}
