import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from '../services/profile/profile.service';
import { Router } from '@angular/router';
import { Profile } from '../services/profile/profile.data';
import { ConfirmPinDialogComponent } from 'src/app/shared-core/components/confirm-pin-dialog/confirm-pin-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EUserType } from '../services/profile/user-type.enum';
import { CreatePinDialogComponent } from 'src/app/setting/components/create-pin-dialog/create-pin-dialog.component';
import { UpdatedPinCode } from '../services/profile/updated-pin-code.data';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'app-choose-profile-page',
  templateUrl: './choose-profile-page.component.html',
  styleUrls: ['./choose-profile-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseProfilePage implements OnInit, OnDestroy {
  // Profiles
  profiles = this.profileService.profiles;
  profileError = this.profileService.profilesError;
  userType = EUserType;

  profileSub!: Subscription;

  constructor(
    private profileService: ProfileService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.profileSub = this.profileService.getProfiles().subscribe({
      next: ((profiles) => {
        if (!profiles || profiles.length === 0) {
          this.router.navigateByUrl(`setting/onboarding`);
        }
      })
    });
  }

  ngOnDestroy(): void {
    this.profileSub.unsubscribe();
  }

  chooseProfile(chosenProfile: Profile): void {
    // First, check if the profile is of type 'adult'
    if (chosenProfile.userType === this.userType.Adult) {
      // If the profile is of type 'adult', open the confirmPin dialog
      this.openConfirmPin(chosenProfile);
    } else {
      // If it's not an 'adult' profile, navigate directly
      this.profileService.setCurrentProfile(chosenProfile);
      this.router.navigateByUrl('/home');
    }
  }

  openConfirmPin(chosenProfile: Profile) {
    if (chosenProfile.hasPinCode) {
      const dialogRef = this.dialog.open(ConfirmPinDialogComponent, {
        data: chosenProfile.id,
        panelClass: 'delete-dialog',
        maxWidth: '325px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          // If the PIN is confirmed, set the profile and navigate
          this.profileService.setCurrentProfile(chosenProfile);
          this.router.navigateByUrl('/home');
        }
      });
    } else {
      const dialogRef = this.dialog.open(CreatePinDialogComponent, {
        data: chosenProfile.id,
        panelClass: 'delete-dialog',
        maxWidth: '325px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const updatedPinCode: UpdatedPinCode = {
            pinCode: result as string,
          };
          this.profileService
            .updatePinCode(chosenProfile.id, updatedPinCode)
            .pipe(take(1))
            .subscribe(() => {
              this.profileService.setCurrentProfile(chosenProfile);
              this.router.navigateByUrl('/home');
            });
        }
      });
    }
  }
}
