import { BrowserModule} from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { environment } from '../environments/environment';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { domain, clientId, callbackUri } from './auth.config';
import { ProfileInterceptor } from './core/interceptors/profile.interceptor';
import { Router } from '@angular/router';
import { TraceService, createErrorHandler } from '@sentry/angular';
import { provideLottieOptions } from 'ngx-lottie';
import { LoadingInterceptor } from './core/interceptors/loading.interceptor';
import { AuthHttpInterceptorExtended } from './core/interceptors/auth0-http-extended.interceptor';

export function httpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(httpClient);
}

const redirect_uri = callbackUri;

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CoreModule,
        AuthModule.forRoot({
            domain,
            clientId,
            // For using Auth0-Angular with Ionic on Android and iOS,
            // it's important to use refresh tokens without the falback
            useRefreshTokens: true,
            useRefreshTokensFallback: false,
            authorizationParams: {
                // Request this audience at user authentication time
                audience: environment.apiUri,
                redirect_uri,
            },
            cacheLocation: 'localstorage',
            // Specify configuration for the interceptor
            httpInterceptor: {
                allowedList: [`${environment.apiUri}/*`],
            }
        })],
        providers: [
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: environment.sentry.showDialog,
            }),
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptorExtended, multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: ProfileInterceptor, multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
        },
        {
            provide: TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [TraceService],
            multi: true,
        },
        provideLottieOptions({
            player: () => import('lottie-web'),
        }),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {
}
