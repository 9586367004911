import { HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthHttpInterceptor } from "@auth0/auth0-angular";
import { Observable } from "rxjs";

@Injectable()
export class AuthHttpInterceptorExtended  extends AuthHttpInterceptor {  

  intercept(req: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('CookieConsents')) {
      return next.handle(req);
    }
    else {
      return super.intercept(req, next);
    }
  }
}