<div class="confirm-wrapper">
  @if (showInitialPin()) {
  <h1>{{ "add-pin.add-pin" | translate }}</h1>
  } @if (showConfirmPin()) {
  <h1>{{ "add-pin.confirm-pin" | translate }}</h1>
  }

  <div class="lottie-wrapper-pin">
    <ng-lottie
      [options]="lock"
      width="165px"
      height="165px"
      (animationCreated)="animationCreated($event)"
    />
  </div>
  @if (showInitialPin()) {
  <form class="pin-form" [formGroup]="pinForm">
    <input
      matInput
      #firstPinInput
      id="digit1"
      class="form-input"
      type="text"
      pattern="[0-9]*"
      maxlength="1"
      formControlName="pin1"
      required
      [readonly]="pin1IsReadOnly()"
      (keyup)="onKeyUp(0, 'pin', $event)"
      (input)="validateDigit($event)"
    />
    <input
      matInput
      id="digit2"
      class="form-input"
      type="text"
      pattern="[0-9]*"
      maxlength="1"
      formControlName="pin2"
      required
      [readonly]="pin2IsReadOnly()"
      (keyup)="onKeyUp(1, 'pin', $event)"
      (input)="validateDigit($event)"
    />
    <input
      matInput
      id="digit3"
      class="form-input"
      type="text"
      pattern="[0-9]*"
      maxlength="1"
      formControlName="pin3"
      required
      [readonly]="pin3IsReadOnly()"
      (keyup)="onKeyUp(2, 'pin', $event)"
      (input)="validateDigit($event)"
    />
    <input
      matInput
      id="digit4"
      class="form-input"
      type="text"
      pattern="[0-9]*"
      maxlength="1"
      formControlName="pin4"
      required
      [readonly]="pin4IsReadOnly()"
      (keyup)="onKeyUp(3, 'pin', $event)"
      (input)="validateDigit($event)"
    />
  </form>
  } @if (showConfirmPin()) {
  <form class="pin-form" [formGroup]="confirmPinForm">
    <input
      matInput
      #firstConfirmPinInput
      id="digit1"
      class="form-input"
      type="text"
      pattern="[0-9]*"
      maxlength="1"
      formControlName="confirmPin1"
      required
      (keyup)="onKeyUp(0, 'confirm', $event)"
      (input)="validateDigit($event)"
    />
    <input
      matInput
      id="digit2"
      class="form-input"
      type="text"
      pattern="[0-9]*"
      maxlength="1"
      formControlName="confirmPin2"
      required
      (keyup)="onKeyUp(1, 'confirm', $event)"
      (input)="validateDigit($event)"
    />
    <input
      matInput
      id="digit3"
      class="form-input"
      type="text"
      pattern="[0-9]*"
      maxlength="1"
      formControlName="confirmPin3"
      required
      (keyup)="onKeyUp(2, 'confirm', $event)"
      (input)="validateDigit($event)"
    />
    <input
      matInput
      id="digit4"
      class="form-input"
      type="text"
      pattern="[0-9]*"
      maxlength="1"
      formControlName="confirmPin4"
      required
      (keyup)="onKeyUp(3, 'confirm', $event)"
      (input)="validateDigit($event)"
    />
  </form>
  @if (showNumberMessage()) {
  <p style="margin: 16px 24px; font-weight: 500">
    {{ "add-pin.insert-number" | translate }}
  </p>
  } } @if (showStartMessage()) {
  <p style="margin: 16px 24px">
    {{ "add-pin.start-message" | translate }}
  </p>
  }
  <!-- @if (showSuccesMessage()) {
  <p style="margin: 16px 24px">
    {{ "add-pin.succes-message" | translate }}
  </p>
  }  -->
  @if (showErrorMessage()) {
  <p style="margin: 16px 24px">
    {{ "add-pin.error-message" | translate : { name: remainingAttempts } }}
    <!-- Du har tastet den forkerte pin! Du har
      {{ remainingAttempts + 1 }} forsøg tilbage. -->
  </p>
  } @if (showErrorMessageReset()) {
  <p style="margin: 16px 24px">
    {{ "add-pin.reset-message" | translate }}
  </p>
  }
</div>
