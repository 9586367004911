import { HttpClient } from "@angular/common/http";
import { computed, Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { catchError, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { WheelOfSummer } from "./wheel-of-summer.data";
import { UpdatedWheelOfSummer } from "./updated-wheel-of-summer.data";
import { NewWheelOfSummer } from "./new-wheel-of-summer.data";
import { ResultArray } from "../result-array.data";
import { toSignal } from "@angular/core/rxjs-interop";
import { HttpErrorService } from "../error/http-error.service";
import { CachingService } from "../caching/caching.service";

@Injectable({
  providedIn: 'root'
})
export class WheelOfSummerService {
  private wheelsUrl = `${environment.apiUri}/v1.0/wheelsofsummer`;
  private wheelModifiedSubject = new BehaviorSubject<void>(undefined);

  private wheelsResult$ = combineLatest([
    this.cachingService.globalRefresh$,
    this.wheelModifiedSubject
  ]).pipe(
    switchMap(() => {
      return this.http.get<WheelOfSummer[]>(this.wheelsUrl)
    }),
    map(wheels => ({ data: wheels } as ResultArray<WheelOfSummer[]>)),
    shareReplay(1),
    catchError(err => {
      console.error(this.httpErrorService.formatError(err));
      return of({ data: [], error: this.httpErrorService.formatError(err) } as ResultArray<WheelOfSummer[]>);
    })
  );
  private wheelsResult = toSignal(
    this.wheelsResult$,
    { initialValue: ({ data: [] } as ResultArray<WheelOfSummer[]>) }
  );
  wheels = computed(() => this.wheelsResult().data);
  wheelsError = computed(() => this.wheelsResult().error);

  constructor(
    private http: HttpClient,
    private httpErrorService: HttpErrorService,
    private cachingService: CachingService
  ) { }

  addWheel(newWheel: NewWheelOfSummer): Observable<WheelOfSummer> {
    return this.http.post<WheelOfSummer>(`${this.wheelsUrl}`, newWheel).pipe(
      tap(value => {
        this.wheelModifiedSubject.next();
      }),
      catchError(this.httpErrorService.handleError)
    );
  }

  updateWheel(wheel: WheelOfSummer): Observable<WheelOfSummer> {
    const updatedWheel: UpdatedWheelOfSummer = {
      date: wheel.date,
      activityIds: wheel.activityIds
    };

    return this.http.put<WheelOfSummer>(`${this.wheelsUrl}/${wheel.id}`, updatedWheel).pipe(
      tap(value => {
        this.wheelModifiedSubject.next();
      }),
      catchError(this.httpErrorService.handleError)
    );
  }
}
