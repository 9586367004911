import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, combineLatest, from, Observable, of, shareReplay, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpErrorService } from '../error/http-error.service';
import { CookieConsent } from './cookie-consent.data';
import { Device } from '@capacitor/device';
import { UpsertedCookieConsent } from './upserted-cookie-consent.data';

@Injectable({ providedIn: 'root' })
export class CookieConsentService {
  private cookieConsentsUrl = `${environment.apiUri}/v1.0/CookieConsents`;
  private consentModifiedSubject = new BehaviorSubject<void>(undefined);

  cookieConsent$ = combineLatest([
    this.consentModifiedSubject,
    from(Device.getId())
  ]).pipe(
    switchMap(([modified, deviceId]) => {
      return this.http.get<CookieConsent>(`${this.cookieConsentsUrl}/${deviceId.identifier}`);
    }),
    shareReplay(1),
    catchError(err => {
      console.error(this.httpErrorService.formatError(err));
      return of(null);
    })
  );

  constructor(
    private http: HttpClient,
    private httpErrorService: HttpErrorService
  ) { }

  upsertConsent(consent: CookieConsent): Observable<any> {
    const upsertedConsent: UpsertedCookieConsent = {
      necessaryCookies: consent.necessaryCookies,
      functionalCookies: consent.functionalCookies
    };

    return this.http.put(`${this.cookieConsentsUrl}/${consent.deviceId}`, upsertedConsent).pipe(
      catchError(this.httpErrorService.handleError)
    );
  }
}
