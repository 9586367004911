import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToDo } from '../../services/to-do/to-do.data';

@Component({
  selector: 'app-childrens-tasks',
  templateUrl: './childrens-tasks.component.html',
  styleUrls: ['./childrens-tasks.component.scss'],
})
export class ChildrensTasksComponent {
  @Input() tasks: ToDo[] = [];
  @Output() chooseTaskEvent = new EventEmitter();

  constructor() {}

  chooseTask(task: ToDo) {
    this.chooseTaskEvent.emit(task);
  }
}
