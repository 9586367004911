import { AfterViewInit, Component, computed } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EToDoType } from '../../services/to-do/to-do-type.enum';
import { take } from 'rxjs';
import { ProfileService } from '../../services/profile/profile.service';
import { ToDoService } from '../../services/to-do/to-do.service';
import { CalEventService } from '../../services/calendar/cal-event.service';
import { CountdownService } from '../../services/countdown/countdown.service';
import { RewardService } from '../../services/reward/reward.service';
import { WheelOfFortuneService } from '../../services/wheel-of-fortune/wheel-of-fortune.service';
import { ToDo } from '../../services/to-do/to-do.data';
import { TranslateService } from '@ngx-translate/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RewardDialogComponent } from 'src/app/shared-core/components/reward-dialog/reward-dialog.component';
import { AnimationOptions } from 'ngx-lottie';
import { FeatureFlagService } from '../../services/feature-flagging/feature-flagging.service';
import { AnimationItem } from 'lottie-web';
import { LoadingService } from '../../services/loading/loading.service';

@Component({
  selector: 'app-tween-home',
  templateUrl: './tween-home.component.html',
  styleUrls: ['./tween-home.component.scss'],
})
export class TweenHomeComponent implements AfterViewInit {
  private animationItem: AnimationItem | undefined;

  ghost: AnimationOptions = {
    path: '/assets/lottie/ghost.json',
    autoplay: false,
  };

  translation = toSignal(this.translateService.get('home-page.daysLeft'));

  mummyAudio = new Audio();
  ghostAudio = new Audio();

  currentProfile = this.profileService.currentProfile;
  myEventsToDay = this.eventService.myEventsToday;
  eventError = this.eventService.todaysEventsError;
  toDoError = this.toDoService.toDosError;
  countdownError = this.countdownService.countdownsError;
  myWheel = this.wheelService.myWheel;
  wheelError = this.wheelService.wheelsError;
  rewardError = this.rewardService.rewardsError;
  featureFlagList = this.featureFlagService.featureFlagList;

  myToDosToDay = computed(() => {
    let toDos = this.toDoService.myToDosToDay().filter((t) => !t.done);

    toDos.forEach((toDo) => {
      toDo.imageStr = toDo.imageStr
        ? toDo.imageStr
        : './assets/images/childrens-single-assignment.png';
    });
    return toDos;
  });

  myCountdowns = computed(() => {
    let countdowns = this.countdownService
      .myCountdowns()
      .filter((c) => c.daysLeft >= 0);
    const sortedCountdowns = countdowns.sort((a, b) => a.daysLeft - b.daysLeft);
    return sortedCountdowns.map((c) => ({
      id: c.id,
      url: c.imageStr,
      title: c.name,
      countdown: this.translation().replace('{{ daysLeft }}', c.daysLeft),
      daysLeft: c.daysLeft,
    }));
  });

  rewardToDo = computed(() => {
    const myRewardToDosToDay = this.toDoService.myRewardToDosToDay();
    return myRewardToDosToDay && myRewardToDosToDay.length > 0
      ? myRewardToDosToDay[0]
      : null;
  });

  loading$ = this.loadingService.loading$;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private profileService: ProfileService,
    private toDoService: ToDoService,
    private eventService: CalEventService,
    private countdownService: CountdownService,
    private wheelService: WheelOfFortuneService,
    private rewardService: RewardService,
    private translateService: TranslateService,
    private featureFlagService: FeatureFlagService,
    private loadingService: LoadingService
  ) {}

  ngAfterViewInit(): void {
    if (this.featureFlagList() && this.featureFlagList()!.halloween) {
      /* ----------- Start audio when page loads ------------ */
      this.mummyAudio.src = '../../../../assets/sounds/scary.mp3';
      this.mummyAudio.load();
      this.ghostAudio.src = '../../../../assets/sounds/ghost.mp3';
      this.ghostAudio.load();
      this.startMummyAnimation();
    }
  }

  navigateToDo(toDo: ToDo) {
    if (toDo.type === EToDoType.List) {
      this.router.navigateByUrl(`/tween/lists/${toDo.id}`);
    } else if (toDo.type === EToDoType.Single) {
      this.router.navigateByUrl(`/tween/tasks/${toDo.id}`);
    }
  }

  done(value: any): void {
    if (value.currentTarget.checked) {
      this.toDoService
        .doToDo(this.rewardToDo()!.id)
        .pipe(
          take(1) // to complete the subscription immediately
        )
        .subscribe();
    } else {
      this.toDoService
        .undoToDo(this.rewardToDo()!.id)
        .pipe(
          take(1) // to complete the subscription immediately
        )
        .subscribe();
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  play(): void {
    if (this.animationItem) {
      this.animationItem.play();
      this.ghostAudio.play();

      setTimeout(() => {
        this.animationItem?.stop();
      }, 3500);
    }
  }

  navigateRewardDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    const dialogRef = this.dialog.open(RewardDialogComponent, {
      // data: event,
      panelClass: 'kid-reward-dialog',
      height: '100%',
      maxHeight: '100%',
      width: '100%',
      maxWidth: '100%',
      enterAnimationDuration,
      exitAnimationDuration,
      autoFocus: false,
      data: this.rewardToDo(),
    });
  }

  startMummyAnimation() {
    const myMummy = <HTMLElement>document.getElementById('animatedMummy');
    myMummy.addEventListener('click', (e) => {
      e.preventDefault();
      this.mummyAudio.play();

      //adding animate class to button
      myMummy.classList.add('mummyanimate');

      setTimeout(() => {
        //removing animate class after 600ms
        myMummy.classList.remove('mummyanimate');
      }, 600);
    });
  }
}
