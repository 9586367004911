import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { EUserType } from 'src/app/core/services/profile/user-type.enum';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProfileService } from 'src/app/core/services/profile/profile.service';
import { take } from 'rxjs';
@Component({
  selector: 'app-create-pin-dialog',
  templateUrl: './create-pin-dialog.component.html',
  styleUrl: './create-pin-dialog.component.scss',
})
export class CreatePinDialogComponent implements OnInit {
  lock: AnimationOptions = {
    path: '/assets/lottie/lock.json',
    autoplay: false,
  };

  pinForm!: UntypedFormGroup;
  confirmPinForm!: UntypedFormGroup;
  isPinCorrect: boolean = false;

  userCode: string = '';
  pin1IsReadOnly = signal<boolean>(false);
  pin2IsReadOnly = signal<boolean>(true);
  pin3IsReadOnly = signal<boolean>(true);
  pin4IsReadOnly = signal<boolean>(true);
  showInitialPin = signal<boolean>(true);
  showConfirmPin = signal<boolean>(false); // by default show the initial pin
  showStartMessage = signal<boolean>(true);
  showErrorMessageReset = signal<boolean>(false);
  showSuccesMessage = signal<boolean>(false);
  showErrorMessage = signal<boolean>(false);
  animationItem!: AnimationItem;
  remainingAttempts: number = 3; // Attempts left for PIN confirmation
  animationLoaded = signal<boolean>(false);
  showNumberMessage = signal<boolean>(false);

  @ViewChild('firstPinInput', { static: false }) firstPinInput!: ElementRef;
  @ViewChild('firstConfirmPinInput', { static: false })
  firstConfirmPinInput!: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreatePinDialogComponent>,
    private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA) private profileId: number
  ) {}

  ngOnInit(): void {
    this.setupPinForm();
    this.setupConfirmPinForm();
  }

  ngAfterViewInit(): void {
    if (this.firstPinInput) {
      this.firstPinInput.nativeElement.focus();
    }
  }

  clearPin() {
    this.pinForm.patchValue({
      pin1: null,
      pin2: null,
      pin3: null,
      pin4: null,
    });
  }

  get pinControls() {
    return [
      this.pinForm.get('digit1') as FormControl,
      this.pinForm.get('digit2') as FormControl,
      this.pinForm.get('digit3') as FormControl,
      this.pinForm.get('digit4') as FormControl,
    ];
  }

  onKeyUp(
    currentDigit: number,
    formType: 'pin' | 'confirm',
    event: KeyboardEvent
  ): void {
    const input = event.target as HTMLInputElement;
    // Ensure only numbers are entered
    if (!/^\d$/.test(input.value)) {
      input.value = ''; // Clear non-numeric input
      this.showNumberMessage.set(true);
      return;
    } else {
      this.showNumberMessage.set(false);
    }
    const form = formType === 'pin' ? this.pinForm : this.confirmPinForm;

    // Move focus to the next input if the current one has a value
    if (input.value && currentDigit < this.pinControls.length - 1) {
      const nextElement = document.getElementById(`digit${currentDigit + 2}`);
      nextElement?.focus();
    }

    if (form.valid) {
      if (formType === 'pin') {
        // Initial PIN entry form completed
        this.handlePinEntryComplete();
      } else {
        // Confirm PIN form completed
        this.handleConfirmPinEntryComplete();
      }
    }
  }

  handlePinEntryComplete() {
    const values = [
      this.pinForm.get('pin1')?.value,
      this.pinForm.get('pin2')?.value,
      this.pinForm.get('pin3')?.value,
      this.pinForm.get('pin4')?.value,
    ];
    this.userCode = values.join('');
    this.showInitialPin.set(false);
    this.showConfirmPin.set(true); // Switch to the confirm PIN form
    this.showStartMessage.set(false);
    this.showErrorMessageReset.set(false); // Show success message

    this.firstConfirmPinInput?.nativeElement.focus();
  }

  handleConfirmPinEntryComplete() {
    const confirmValues = [
      this.confirmPinForm.get('confirmPin1')?.value,
      this.confirmPinForm.get('confirmPin2')?.value,
      this.confirmPinForm.get('confirmPin3')?.value,
      this.confirmPinForm.get('confirmPin4')?.value,
    ];
    const confirmCode = confirmValues.join('');

    if (this.userCode === confirmCode) {
      this.showSuccesMessage.set(true); // Show success message
      this.showErrorMessage.set(false); // Show success message
      this.play();
    } else {
      this.remainingAttempts--;
      this.confirmPinForm.reset();
      this.firstConfirmPinInput?.nativeElement.focus();
      this.showErrorMessage.set(true); // Show success message
      this.showSuccesMessage.set(false); // Show success message

      if (this.remainingAttempts <= 0) {
        // If no attempts left, reset the entire form
        this.reset();
        this.showErrorMessage.set(false); // Show success message
        this.showErrorMessageReset.set(true); // Show success message
      }
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  complete(animationItem: AnimationItem): void {
    if (this.animationItem) {
      this.animationItem.stop();
    }
  }

  play(): void {
    if (this.animationItem) {
      this.animationItem.play();
      this.animationItem.autoplay = false;

      setTimeout(() => {
        this.animationItem?.pause();
        this.closeDialog();
      }, 1500);
    }
  }
  closeDialog(): void {
    this.dialogRef.close(this.userCode);
  }

  // Reset all forms and clear variables
  reset(): void {
    this.pinForm.reset();
    this.firstPinInput?.nativeElement.focus(); // Focus on the first PIN input again
    this.confirmPinForm.reset();
    this.userCode = '';
    this.showConfirmPin.set(false); // Return to the initial PIN form
    this.showInitialPin.set(true);
    this.showStartMessage.set(false);
    this.remainingAttempts = 3; // Reset attempts counter
  }

  private setupPinForm() {
    this.pinForm = this.fb.group({
      pin1: [null, Validators.required],
      pin2: [null, Validators.required],
      pin3: [null, Validators.required],
      pin4: [null, Validators.required],
    });

    this.pinForm.controls['pin1'].valueChanges.subscribe((value) => {
      if (value) {
        this.pin2IsReadOnly.set(false);
      }
    });

    this.pinForm.controls['pin2'].valueChanges.subscribe((value) => {
      if (value) {
        this.pin3IsReadOnly.set(false);
      }
    });

    this.pinForm.controls['pin3'].valueChanges.subscribe((value) => {
      if (value) {
        this.pin4IsReadOnly.set(false);
      }
    });
  }

  private setupConfirmPinForm() {
    this.confirmPinForm = this.fb.group({
      confirmPin1: [null, Validators.required],
      confirmPin2: [null, Validators.required],
      confirmPin3: [null, Validators.required],
      confirmPin4: [null, Validators.required],
    });

    this.confirmPinForm.controls['confirmPin1'].valueChanges.subscribe(
      (value) => {
        if (value) {
          this.pin2IsReadOnly.set(false);
        }
      }
    );

    this.confirmPinForm.controls['confirmPin2'].valueChanges.subscribe(
      (value) => {
        if (value) {
          this.pin3IsReadOnly.set(false);
        }
      }
    );

    this.confirmPinForm.controls['confirmPin3'].valueChanges.subscribe(
      (value) => {
        if (value) {
          this.pin4IsReadOnly.set(false);
        }
      }
    );
  }

  validateDigit(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    // Tillad kun tal mellem 0-9
    if (!/^[0-9]$/.test(value)) {
      input.value = ''; // Nulstil feltet, hvis det ikke er et tal mellem 0-9
    }
  }
}
