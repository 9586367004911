import { Component, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  isAuthenticated = toSignal(this.authService.isAuthenticated$);

  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if(this.isAuthenticated() === true) {
      this.router.navigateByUrl(`home`);
    }
  }

  login() {
    this.authService.loginWithRedirect({
      authorizationParams: {
        ui_locales: 'da en de'
      },
      async openUrl(url: string) {
        await Browser.open({ url, windowName: '_self' });
      }
    }).subscribe();
  }
}
