import {AppUpdate} from '@capawesome/capacitor-app-update';
import {Injectable} from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpErrorService } from '../error/http-error.service';
import { AvailableAppVersion } from './available-version.data';
import { catchError, of, take } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AppUpdateService {
  private availableAppVersionsUrl = `${environment.apiUri}/v1.0/AvailableAppVersions`;
  private currentAppVersion: number = 1;

  constructor(
      private http: HttpClient,
      private httpErrorService: HttpErrorService
  ) { }

  checkForUpdatesAndForce() {
    this.http.get<AvailableAppVersion>(this.availableAppVersionsUrl).pipe(
      take(1),
      catchError(err => {
        console.error(this.httpErrorService.formatError(err));
        return of(null);
      })
    ).subscribe({
      next: ((availableAppVersion) => {
        if (availableAppVersion && this.currentAppVersion < availableAppVersion.versionNo) {
          this.promptUserToUpdate();
        }
      })
    })
  }


  private promptUserToUpdate() {
    alert('A new update is available. Please update the app to continue.');
    AppUpdate.openAppStore().then(() => { });
  }
}