import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import {from, map, Observable, of, switchMap, take} from 'rxjs';
import { EStorageKey } from 'src/app/shared/enums/storage-key.enum';

@Injectable({
  providedIn: 'root'
})
export class ChosenProfileGuard  {

  constructor(
    private router: Router
  ) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    return this.profileChosen();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.profileChosen();
  }


  private profileChosen(): Observable<boolean | UrlTree> {
    return this.getSavedProfileId().pipe(
      take(1),
      switchMap(savedProfileId => {
        if (!savedProfileId) {
          return of(this.router.parseUrl('/choose-profile'));
        }
        return of(true);
      })
    );
  }

  private getSavedProfileId(): Observable<number | undefined> {
    return from(Preferences.get({key: EStorageKey.ProfileId})).pipe(
      map((profileId) => {
        if (profileId.value) {
          return Number(profileId.value);
        } else {
          return undefined;
        }
      })
    );
  }
}
