@if(isIphone){

<!-- ios -->
@if (currentUniverse()) {
<nav class="navigation">
  <ul>
    <!----------------------------------------------------------------- Home ------------------------------------------------------------------------->
    <li class="list" (click)="navigateHome()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionFirst}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faHouseHeart" size="xl"></fa-icon>
        </span>
        <span class="text">{{ 'toolbar-navigation-home.home' | translate }}</span>
      </div>
    </li>

    <!-- Switch according to current universe -->
    @switch (currentUniverse()) {

    <!----------------------------------------------------------------- To Do -------------------------------------------------------------------------->
    @case (universe.ToDo) {
    <li class="list" (click)="navigateToDos()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionSecond}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faListCheck" size="xl"></fa-icon></span>
        <span class="text">{{ 'toolbar-navigation-todo.todo' | translate }}</span>
      </div>
    </li>

    <li class="list" (click)="addToDo()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionThird}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faPlus" size="xl"></fa-icon>
        </span>
        <span class="text">{{ 'toolbar-navigation-todo.add' | translate }}</span>
      </div>
    </li>


    <li class="list" (click)="navigateToDoMore()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionFourth}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faEllipsisStrokeVertical" size="xl"></fa-icon>
        </span>
        <span class="text">{{ 'toolbar-navigation-todo.more' | translate }}</span>
      </div>
    </li>
    }

    <!------------------------------------------------------------------- Rewards ----------------------------------------------------------------------------------->
    @case (universe.Reward) {
    <li class="list" (click)="navigateRewards()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionSecond}">
      <div class="button">
        <!-- <span class="icon"><fa-icon [icon]="faGift" size="xl"></fa-icon></span> -->
        <span class="icon">
          @if (currentCirkelPosition() === enumnavposition.PositionSecond) {
          <img class="reward-icon" src="../../../../assets/images/tab-bar/wheel-black.svg" alt="" width="21px"
            height="21px">
          }
          @else {
          <img class="reward-icon" src="../../../../assets/images/tab-bar/wheel-white.svg" alt="" width="21px"
            height="21px">
          }
        </span>
        <span class="text">{{ 'toolbar-navigation-reward.rewards' | translate }}</span>
      </div>
    </li>

    <li class="list" (click)="addReward()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionThird}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faPlus" size="xl"></fa-icon>
        </span>
        <span class="text">{{ 'toolbar-navigation-reward.add' | translate }}</span>
      </div>
    </li>


    <li class="list" (click)="navigateRewardMore()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionFourth}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faEllipsisStrokeVertical" size="xl"></fa-icon>
        </span>
        <span class="text">{{ 'toolbar-navigation-todo.more' | translate }}</span>
      </div>
    </li>
    }

    <!-------------------------------------------------------------------- Catalog -------------------------------------------------------------------->
    @case (universe.Catalog) {
    <li class="list" (click)="navigateCatalogSearch()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionSecond}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faLightbulbOn" size="xl"></fa-icon></span>
        <span class="text">{{ 'toolbar-navigation-catalog.search' | translate }}</span>
      </div>
    </li>

    <li class="list" (click)="navigateCatalogAdd()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionThird}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faPlus" size="xl"></fa-icon>
        </span>
        <span class="text">{{ 'toolbar-navigation-catalog.add' | translate }}</span>
      </div>
    </li>


    <li class="list" (click)="navigateCatalogMore()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionFourth}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faEllipsisStrokeVertical" size="xl"></fa-icon>
        </span>
        <span class="text">{{ 'toolbar-navigation-catalog.more' | translate }}</span>
      </div>
    </li>
    }

    <!----------------------------------------------------------------------- Admin --------------------------------------------------------------------------->
    @case (universe.Admin) {

    <li class="list" (click)="navigateAdminMetrics()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionSecond}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faChartUser" size="xl"></fa-icon>
        </span>
        <span class="text">{{ 'toolbar-navigation-admin.metrics' | translate }}</span>
      </div>
    </li>

    <li class="list" (click)="navigateAdminPublications()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionThird}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faUpload" size="xl"></fa-icon></span>
        <span class="text">{{ 'toolbar-navigation-admin.administration' | translate }}</span>
      </div>
    </li>

    <li class="list" (click)="navigateAdminTest()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionFourth}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faMicroscope" size="xl"></fa-icon>
        </span>
        <span class="text">{{ 'toolbar-navigation-admin.test' | translate }}</span>
      </div>
    </li>
    }

    <!-------------------------------------------------------------- Calendar ---------------------------------------------------------------------->
    @case (universe.Calendar) {
    <li class="list" (click)="navigateCalendar()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionSecond}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faCalendarDays" size="xl"></fa-icon></span>
        <span class="text">{{ 'toolbar-navigation-calendar.calendar' | translate }}</span>
      </div>
    </li>

    <li class="list" (click)="addCalendarItem()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionThird}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faPlus" size="xl"></fa-icon>
        </span>
        <span class="text">{{ 'toolbar-navigation-calendar.add' | translate }}</span>
      </div>
    </li>

    <li class="list" (click)="navigateCalendarMore()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionFourth}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faEllipsisStrokeVertical" size="xl"></fa-icon>
        </span>
        <span class="text">{{ 'toolbar-navigation-calendar.more' | translate }}</span>
      </div>
    </li>
    }

      <!------------------------------------------------------------- Scoreboard ----------------------------------------------------------->
      @case (universe.Scoreboard) {
        <li class="list" (click)="navigateWomensScoreboard()"
          [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionSecond}">
          <div class="button">
            <span class="icon"><fa-icon [icon]="faTrophyStar" size="xl"></fa-icon></span>
            <span class="text">{{ 'toolbar-navigation-scoreboard.score' | translate }}</span>
          </div>
        </li>

        <li class="list" (click)="addWomensScoreboard()"
          [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionThird}">
          <div class="button">
            <span class="icon"><fa-icon [icon]="faPlus" size="xl"></fa-icon>
            </span>
            <span class="text">{{ 'toolbar-navigation-scoreboard.add' | translate }}</span>
          </div>
        </li>

        <li class="list" (click)="navigateWomensScoreboardStats()"
          [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionFourth}">
          <div class="button">
            <span class="icon"><fa-icon [icon]="faChartColumn" size="xl"></fa-icon>
            </span>
            <span class="text">{{ 'toolbar-navigation-scoreboard.stats' | translate }}</span>
          </div>
        </li>
      }

      <!------------------------------------------------------------- Food ----------------------------------------------------------->
      @case (universe.Food) {
        <li class="list" (click)="navigateRecipes()"
          [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionSecond}">
          <div class="button">
            <span class="icon"><fa-icon [icon]="faListCheck" size="xl"></fa-icon></span>
            <span class="text">Opskrifter</span>
          </div>
        </li>
    
        <li class="list" (click)="addRecipe()"
          [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionThird}">
          <div class="button">
            <span class="icon"><fa-icon [icon]="faPlus" size="xl"></fa-icon>
            </span>
            <span class="text">Opret</span>
          </div>
        </li>
    
    
        <li class="list" (click)="navigateFoodMore()"
          [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionFourth}">
          <div class="button">
            <span class="icon"><fa-icon [icon]="faEllipsisStrokeVertical" size="xl"></fa-icon>
            </span>
            <span class="text">Mere</span>
          </div>
        </li>
      }
    }

    <!---------------------------------------------------------------------- Universes ------------------------------------------------------------------->
    <li class="list" (click)="navigateUniverses()"
      [ngClass]="{'active': currentCirkelPosition() === enumnavposition.PositionFifth}">
      <div class="button">
        <span class="icon"><fa-icon [icon]="faObjectsColumn" size="xl"></fa-icon>
        </span>
        <span class="text">{{ 'toolbar-navigation-todo.universe' | translate }}</span>
      </div>
    </li>
    <div class="indicator"></div>
  </ul>
</nav>
}
} @else
{
<!-- android or web -->
@if (currentUniverse()) {
  <nav class="navigation2">
    <ul>
      <!----------------------------------------------------------------- Home ------------------------------------------------------------------------->
      <li class="list2" (click)="navigateHome()"
        [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionFirst}">
        <div class="button2">
          <span class="icon2"><fa-icon [icon]="faHouseHeart" size="xl"></fa-icon>
          </span>
          <span class="text2">{{ 'toolbar-navigation-home.home' | translate }}</span>
        </div>
      </li>

    <!-- Switch according to current universe -->
    @switch (currentUniverse()) {

    <!----------------------------------------------------------------- To Do -------------------------------------------------------------------------->
    @case (universe.ToDo) {
    <li class="list2" (click)="navigateToDos()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionSecond}">
      <div class="button2">
        <span class="icon2"><fa-icon [icon]="faListCheck" size="xl"></fa-icon></span>
        <span class="text2">{{ 'toolbar-navigation-todo.todo' | translate }}</span>
      </div>
    </li>

    <li class="list2" (click)="addToDo()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionThird}">
      <div class="button2">
        <span class="icon2"><fa-icon [icon]="faPlus" size="xl"></fa-icon>
        </span>
        <span class="text2">{{ 'toolbar-navigation-todo.add' | translate }}</span>
      </div>
    </li>


    <li class="list2" (click)="navigateToDoMore()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionFourth}">
      <div class="button2">
        <span class="icon2"><fa-icon [icon]="faEllipsisStrokeVertical" size="xl"></fa-icon>
        </span>
        <span class="text2">{{ 'toolbar-navigation-todo.more' | translate }}</span>
      </div>
    </li>
    }

    <!------------------------------------------------------------------- Rewards ----------------------------------------------------------------------------------->
    @case (universe.Reward) {
    <li class="list2" (click)="navigateRewards()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionSecond}">
      <div class="button2">
        <!-- <span class="icon"><fa-icon [icon]="faGift" size="xl"></fa-icon></span> -->
        <span class="icon2">
          @if (currentCirkelPosition() === enumnavposition.PositionSecond) {
          <img class="reward-icon2" src="../../../../assets/images/tab-bar/wheel-black.svg" alt="" width="21px"
            height="21px">
          }
          @else {
          <img class="reward-icon2" src="../../../../assets/images/tab-bar/wheel-white.svg" alt="" width="21px"
            height="21px">
          }
        </span>
        <span class="text2">{{ 'toolbar-navigation-reward.rewards' | translate }}</span>
      </div>
    </li>
 
    <li class="list2" (click)="addReward()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionThird}">
      <div class="button2">
        <span class="icon2"><fa-icon [icon]="faPlus" size="xl"></fa-icon>
        </span>
        <span class="text2">{{ 'toolbar-navigation-reward.add' | translate }}</span>
      </div>
    </li>


    <li class="list2" (click)="navigateRewardMore()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionFourth}">
      <div class="button2">
        <span class="icon2"><fa-icon [icon]="faEllipsisStrokeVertical" size="xl"></fa-icon>
        </span>
        <span class="text2">{{ 'toolbar-navigation-todo.more' | translate }}</span>
      </div>
    </li>
    }

    <!-------------------------------------------------------------------- Catalog -------------------------------------------------------------------->
    @case (universe.Catalog) {
    <li class="list2" (click)="navigateCatalogSearch()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionSecond}">
      <div class="button2">
        <span class="icon2"><fa-icon [icon]="faLightbulbOn" size="xl"></fa-icon></span>
        <span class="text2">{{ 'toolbar-navigation-catalog.search' | translate }}</span>
      </div>
    </li>

    <li class="list2" (click)="navigateCatalogAdd()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionThird}">
      <div class="button2">
        <span class="icon2"><fa-icon [icon]="faPlus" size="xl"></fa-icon>
        </span>
        <span class="text2">{{ 'toolbar-navigation-catalog.add' | translate }}</span>
      </div>
    </li>


    <li class="list2" (click)="navigateCatalogMore()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionFourth}">
      <div class="button2">
        <span class="icon2"><fa-icon [icon]="faEllipsisStrokeVertical" size="xl"></fa-icon>
        </span>
        <span class="text2">{{ 'toolbar-navigation-catalog.more' | translate }}</span>
      </div>
    </li>
    }

    <!----------------------------------------------------------------------- Admin --------------------------------------------------------------------------->
    @case (universe.Admin) {

    <li class="list2" (click)="navigateAdminMetrics()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionSecond}">
      <div class="button2">
        <span class="icon2"><fa-icon [icon]="faChartUser" size="xl"></fa-icon>
        </span>
        <span class="text2">{{ 'toolbar-navigation-admin.metrics' | translate }}</span>
      </div>
    </li>

    <li class="list2" (click)="navigateAdminPublications()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionThird}">
      <div class="button2">
        <span class="icon2"><fa-icon [icon]="faUpload" size="xl"></fa-icon></span>
        <span class="text2">{{ 'toolbar-navigation-admin.administration' | translate }}</span>
      </div>
    </li>

    <li class="list2" (click)="navigateAdminTest()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionFourth}">
      <div class="button2">
        <span class="icon2"><fa-icon [icon]="faMicroscope" size="xl"></fa-icon>
        </span>
        <span class="text2">{{ 'toolbar-navigation-admin.test' | translate }}</span>
      </div>
    </li>
    }

    <!-------------------------------------------------------------- Calendar ---------------------------------------------------------------------->
    @case (universe.Calendar) {
    <li class="list2" (click)="navigateCalendar()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionSecond}">
      <div class="button2">
        <span class="icon2"><fa-icon [icon]="faCalendarDays" size="xl"></fa-icon></span>
        <span class="text2">{{ 'toolbar-navigation-calendar.calendar' | translate }}</span>
      </div>
    </li>

    <li class="list2" (click)="addCalendarItem()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionThird}">
      <div class="button2">
        <span class="icon2"><fa-icon [icon]="faPlus" size="xl"></fa-icon>
        </span>
        <span class="text2">{{ 'toolbar-navigation-calendar.add' | translate }}</span>
      </div>
    </li>

    <li class="list2" (click)="navigateCalendarMore()"
      [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionFourth}">
      <div class="button2">
        <span class="icon2"><fa-icon [icon]="faEllipsisStrokeVertical" size="xl"></fa-icon>
        </span>
        <span class="text2">{{ 'toolbar-navigation-calendar.more' | translate }}</span>
      </div>
    </li>
    }

      <!------------------------------------------------------------- Scoreboard ----------------------------------------------------------->
      @case (universe.Scoreboard) {
      <li class="list2" (click)="navigateWomensScoreboard()"
        [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionSecond}">
        <div class="button2">
          <span class="icon2"><fa-icon [icon]="faTrophyStar" size="xl"></fa-icon></span>
          <span class="text2">{{ 'toolbar-navigation-scoreboard.score' | translate }}</span>
        </div>
      </li>

      <li class="list2" (click)="addWomensScoreboard()"
        [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionThird}">
        <div class="button2">
          <span class="icon2"><fa-icon [icon]="faPlus" size="xl"></fa-icon>
          </span>
          <span class="text2">{{ 'toolbar-navigation-scoreboard.add' | translate }}</span>
        </div>
      </li>

      <li class="list2" (click)="navigateWomensScoreboardStats()"
        [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionFourth}">
        <div class="button2">
          <span class="icon2"><fa-icon [icon]="faChartColumn" size="xl"></fa-icon>
          </span>
          <span class="text2">{{ 'toolbar-navigation-scoreboard.stats' | translate }}</span>
        </div>
      </li>
      }

      <!------------------------------------------------------------- Food ----------------------------------------------------------->
      @case (universe.Food) {
        <li class="list2" (click)="navigateRecipes()"
          [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionSecond}">
          <div class="button2">
            <span class="icon2"><fa-icon [icon]="faListCheck" size="xl"></fa-icon></span>
            <span class="text2">Opskrifter</span>
          </div>
        </li>
    
        <li class="list2" (click)="addRecipe()"
          [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionThird}">
          <div class="button2">
            <span class="icon2"><fa-icon [icon]="faPlus" size="xl"></fa-icon>
            </span>
            <span class="text2">Opret</span>
          </div>
        </li>
    
        <li class="list2" (click)="navigateFoodMore()"
          [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionFourth}">
          <div class="button2">
            <span class="icon2"><fa-icon [icon]="faEllipsisStrokeVertical" size="xl"></fa-icon>
            </span>
            <span class="text2">Mere</span>
          </div>
        </li>
      }
    }

      <!---------------------------------------------------------------------- Universes ------------------------------------------------------------------->
      <li class="list2" (click)="navigateUniverses()"
        [ngClass]="{'active2': currentCirkelPosition() === enumnavposition.PositionFifth}">
        <div class="button2">
          <span class="icon2"><fa-icon [icon]="faObjectsColumn" size="xl"></fa-icon>
          </span>
          <span class="text2">{{ 'toolbar-navigation-todo.universe' | translate }}</span>
        </div>
      </li>
      <div class="indicator2"></div>
    </ul>
  </nav>
}
}