import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import { Device } from '@capacitor/device';
import { Location } from '@angular/common';
import { CookieConsentService } from '../../services/cookie-consent/cookie-consent.service';
import { Subscription, take } from 'rxjs';
import { CookieConsent } from '../../services/cookie-consent/cookie-consent.data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-consent-banner-dialog',
  templateUrl: './consent-banner-dialog.component.html',
  styleUrl: './consent-banner-dialog.component.scss',
})
export class ConsentBannerDialogComponent implements OnDestroy {
  form!: UntypedFormGroup;
  faArrowUp = faMinus;
  faArrowDown = faPlus;

  cookieConsent!: CookieConsent;
  cookieConsentSub!: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private location: Location,
    private cookieConsentService: CookieConsentService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {
    this.form = this.fb.group({
      functionalCookies: false
    });

    // check if the device has given consent
    this.cookieConsentSub = this.cookieConsentService.cookieConsent$.subscribe({
      next: (cookieConsent) => {
        if (cookieConsent) {
          this.cookieConsent = cookieConsent;

          // update the form with previous consent
          this.form.patchValue({
            functionalCookies: this.cookieConsent.functionalCookies
          });
        } else {
          // create the consent with the device ID
          Device.getId().then((deviceId) => {
            this.cookieConsent = {
              id: -1,
              deviceId: deviceId.identifier,
              necessaryCookies: true,
              functionalCookies: false
            };
            this.changeDetectorRef.detectChanges();
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.cookieConsentSub.unsubscribe();
  }

  navigateBack(): void {
    this.location.back();
  }

  saveAll() {
    this.cookieConsent!.functionalCookies = true;
    this.saveConsent();
  }

  saveChosen() {
    this.cookieConsent!.functionalCookies = this.form.controls.functionalCookies.value;
    this.saveConsent();
  }


  private saveConsent() {
    this.cookieConsentService.upsertConsent(this.cookieConsent).pipe(
      take(1)
    ).subscribe();

    this.router.navigateByUrl('/home');
  }
}
