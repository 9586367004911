<div class="content">
  @if (profileError()) {
  <app-error-img></app-error-img>
  }

  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    "
  >
    <div class="header-container">
      <h2>{{ "choose-profile-dialog.title" | translate }}</h2>
    </div>

    <!-- Profiles -->
    @if (profiles(); as profiles) {
    <section class="image-card-container">
      @for (profile of profiles; track profile.id) {
      <div class="image-card" (click)="chooseProfile(profile)">
        <img [src]="profile.avatarSrc" />
        <p>{{ profile.name }}</p>
      </div>
      }
    </section>
    }
  </div>
</div>
