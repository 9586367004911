import { Component, computed } from '@angular/core';
import { Router } from '@angular/router';
import { faPlus, faSquarePlus } from '@fortawesome/pro-solid-svg-icons';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AnimationOptions } from 'ngx-lottie';
import { AddToDoSheet } from '../add-to-do-sheet/add-to-do-sheet.component';
import { AddToDoSheetModel } from '../add-to-do-sheet/add-to-do-sheet.model';
import {
  BehaviorSubject,
  from,
  map,
  Observable,
  shareReplay,
  switchMap,
  take,
} from 'rxjs';
import { ProfileService } from '../../services/profile/profile.service';
import { ToDoService } from '../../services/to-do/to-do.service';
import { CalEventService } from '../../services/calendar/cal-event.service';
import { CountdownService } from '../../services/countdown/countdown.service';
import { CatalogTagService } from '../../services/catalog/catalog-tag.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { ToDoTask } from '../../services/to-do/to-do-task.data';
import { MatDialog } from '@angular/material/dialog';
import { AddTimetablePageComponent } from 'src/app/calendar/add-timetable-page/add-timetable-page.component';
import { Preferences } from '@capacitor/preferences';
import { EStorageKey } from 'src/app/shared/enums/storage-key.enum';
import { ToDo } from '../../services/to-do/to-do.data';
import { ECatalogItemType } from '../../services/catalog/catalog-item-type.enum';
import { HalloweenGreetingDialogComponent } from 'src/app/halloween/halloween-greeting-dialog/halloween-greeting-dialog.component';
import { FeatureFlagService } from '../../services/feature-flagging/feature-flagging.service';
import { WonActivityService } from '../../services/won-activity/won-activity.service';
import { ActivityQuery } from '../../services/won-activity/activity-query.data';
import { EWonActivityType } from '../../services/won-activity/won-activity-type.enum';
import { EUserType } from '../../services/profile/user-type.enum';
import { TimetableService } from '../../services/timetable/timetable.service';

@Component({
  selector: 'app-adult-home',
  templateUrl: './adult-home.component.html',
  styleUrls: ['./adult-home.component.scss'],
})
export class AdultHomeComponent {
  options: AnimationOptions = {
    path: '/assets/lottie/leaf.json',
  };

  fasquareplus = faSquarePlus;
  faPlus = faPlus;

  currentProfile = this.profileService.currentProfile;
  timetables = this.timetableService.timetables;
  toDoError = this.toDoService.toDosError;
  myEventsToDay = this.eventService.myEventsToday;
  eventError = this.eventService.todaysEventsError;
  countdownError = this.countdownService.countdownsError;
  featureFlagList = this.featureFlagService.featureFlagList;
  featureFlagListError = this.featureFlagService.featureFlagListError;

  children = computed(() => {
    let children = [...this.profileService.profiles()];

    if (children && children.length > 0) {
      children = children.filter((p) => p.userType !== EUserType.Adult);
    }
    return children;
  });

  childrenWithoutTimetable = computed(() => {
    const timetables = this.timetableService.timetables();
    const children = this.profileService
      .profiles()
      .filter((profile) => profile.userType !== EUserType.Adult);

    // Combine the logic with the hideNews state
    return (
      children.some(
        (child) => !timetables.some((t) => t.profileId === child.id)
      ) && !this.hideNews()
    );
  });

  toggledToDos: ToDo[] = [];
  myToDosToDay = computed(() => {
    let toDos = this.toDoService
      .myToDosToDay()
      .concat(this.toDoService.myRewardToDosToDay());

    toDos = this.toDoService.getToggledState(toDos, this.toggledToDos);
    this.toggledToDos = toDos;

    return toDos;
  });

  translation = toSignal(this.translateService.get('home-page.daysLeft'));
  myCountdowns = computed(() => {
    let countdowns = this.countdownService
      .myCountdowns()
      .filter((c) => c.daysLeft >= 0);
    const sortedCountdowns = countdowns.sort((a, b) => a.daysLeft - b.daysLeft);
    return sortedCountdowns.map((c) => ({
      id: c.id,
      url: c.imageStr,
      title: c.name,
      countdown: this.translation().replace('{{ daysLeft }}', c.daysLeft),
      daysLeft: c.daysLeft,
    }));
  });

  private hideCurrentNewsValue = 'timetable';
  private hideNewsSubject = new BehaviorSubject<void>(undefined);
  private hideNews$ = this.hideNewsSubject.pipe(
    switchMap(() => {
      return this.showNews();
    }),
    shareReplay(1)
  );
  hideNews = toSignal(this.hideNews$, { initialValue: false });

  constructor(
    private profileService: ProfileService,
    private toDoService: ToDoService,
    private eventService: CalEventService,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private countdownService: CountdownService,
    private catalogTagsService: CatalogTagService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private featureFlagService: FeatureFlagService,
    private wonActivityService: WonActivityService,
    private timetableService: TimetableService
  ) {}

  addTask() {
    const model: AddToDoSheetModel = {
      currentProfileId: this.currentProfile()!.id,
      profiles: this.profileService.profiles(),
    };
    const bottomSheetRef = this.bottomSheet.open(AddToDoSheet, {
      data: model,
    });

    bottomSheetRef.afterDismissed().subscribe((result) => {
      if (result) {
        this.toDoService
          .addToDo(result)
          .pipe(
            take(1) // to complete the subscription immediately
          )
          .subscribe();
      }
    });
  }

  onToDoToggled(toDoId: number): void {
    const index = this.toggledToDos.findIndex((t) => t.id === toDoId);
    this.toggledToDos[index].expanded = !this.toggledToDos[index].expanded;
  }

  onTaskToggled(task: ToDoTask): void {
    const toDoIndex = this.toggledToDos.findIndex((t) => t.id === task.toDoId);
    const taskIndex = this.toggledToDos[toDoIndex].tasks.findIndex(
      (t) => t.id === task.id
    );
    this.toggledToDos[toDoIndex].tasks[taskIndex].expanded =
      !this.toggledToDos[toDoIndex].tasks[taskIndex].expanded;
  }

  navigateCatalog() {
    this.catalogTagsService.selectedItemType.set(ECatalogItemType.ToDo);
    this.router.navigateByUrl(`catalog/catalog`);
  }

  navigateAddTimetable() {
    this.dialog.open(AddTimetablePageComponent, {
      panelClass: 'app-full-bleed-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false,
    });
  }

  // hideNewsOnHomePage(): void {
  //   Preferences.set({
  //     key: EStorageKey.HideNewsOnHomePage,
  //     value: this.hideCurrentNewsValue,
  //   }).then((value) => {
  //     this.hideNewsSubject.next();
  //   });
  // }

  hideNewsOnHomePage(): void {
    Preferences.set({
      key: EStorageKey.HideNewsOnHomePage,
      value: this.hideCurrentNewsValue,
    }).then(() => {
      this.hideNewsSubject.next(); // Notify subscribers
    });
  }

  navigateHalloweenWheel() {
    const today = new Date();
    const query: ActivityQuery = {
      year: today.getFullYear(),
      type: EWonActivityType.Halloween,
    };
    this.wonActivityService.activityQuery.set(query);

    this.dialog.open(HalloweenGreetingDialogComponent, {
      data: this.wonActivityService.activities(),
      panelClass: 'halloween-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false,
    });
  }

  private showNews(): Observable<boolean> {
    return from(Preferences.get({ key: EStorageKey.HideNewsOnHomePage })).pipe(
      map((hideNewsObject) => {
        let hideNews = false;

        if (
          hideNewsObject.value &&
          hideNewsObject.value === this.hideCurrentNewsValue
        ) {
          hideNews = true;
        }
        return hideNews;
      })
    );
  }
}
