import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, from, map, Observable, switchMap } from "rxjs";
import { Profile } from "../services/profile/profile.data";
import { EStorageKey } from "src/app/shared/enums/storage-key.enum";
import { Preferences } from "@capacitor/preferences";
import { environment } from "src/environments/environment";

@Injectable()
export class ProfileInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    if (req.url.includes(environment.apiUri)) {
      if (req.url.includes('CookieConsents')) {
        return next.handle(req);
      }
      else {
        // Get the current profile
        return this.getSavedProfileId().pipe(
          switchMap(profileId => {
            if (profileId) {
  
              // Clone the request and replace the original headers with
              // cloned headers, updated with the profile ID.
              const authReq = req.clone({
                headers: req.headers.set('Profile', profileId.toString())
              });
  
              // send cloned request with header to the next handler.
              return next.handle(authReq);
            } else {
              return next.handle(req);
            }
          }),
          catchError(err => {
            return next.handle(req);
        }));
      }
    } else {
      return next.handle(req);
    }
  }


  private getSavedProfileId(): Observable<number | undefined> {
    return from(Preferences.get({key: EStorageKey.ProfileId})).pipe(
      map((profileId) => {
        if (profileId.value) {
          return Number(profileId.value);
        } else {
          return undefined;
        }
      })
    );
  }
}