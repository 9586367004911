<mat-toolbar color="primary" class="bottom-sheet-title">
    <span>{{ 'edit-subject-sheet.title' | translate }}</span>
</mat-toolbar>

<div class="sheet-wrapper">
    <form [formGroup]="form" class="form-styling">

        <div class="time-wrapper">
            <div class="form-item">
                <input type="time" formControlName="startTime" id="startTime" placeholder="e.g. 8:00-9:00"
                    class="form-input">
                <label for="startTime" class="form-label">{{ 'edit-subject-sheet.startTime' | translate }}</label>

                @if (form.controls.startTime.touched && form.controls.startTime.invalid && form.controls.startTime.errors?.['required']) {
                    <mat-error>
                      <span>{{ 'add-subject-sheet.required' | translate }}</span>
                    </mat-error>
                }
            </div>

            <div class="form-item">
                <input type="time" formControlName="endTime" id="endTime" placeholder="e.g. 8:00-9:00"
                    class="form-input">
                <label for="endTime" class="form-label">{{ 'edit-subject-sheet.endTime' | translate }}</label>

                @if (form.controls.endTime.touched && form.controls.endTime.invalid && form.controls.endTime.errors?.['required']) {
                    <mat-error>
                      <span>{{ 'add-subject-sheet.required' | translate }}</span>
                    </mat-error>
                }
            </div>
        </div>

        <div class="form-item-full">
            <input type="text" formControlName="name" id="name" [matAutocomplete]="autoSubject" class="form-input-full" autocomplete="off">
            <label for="name" class="form-label">{{ 'edit-subject-sheet.subject' | translate }}</label>

            <mat-autocomplete #autoSubject="matAutocomplete">
                @for (subject of input.knownSubjects; track $index) {
                    <mat-option [value]="subject">
                        {{subject}}
                    </mat-option>
                }
            </mat-autocomplete>

            @if (form.controls.name.touched && form.controls.name.invalid && form.controls.name.errors?.['required']) {
                <mat-error>
                  <span>{{ 'add-subject-sheet.required' | translate }}</span>
                </mat-error>
            }
        </div>

        <div class="time-wrapper">
            <div class="form-item">
                <input type="text" formControlName="room" id="room" [matAutocomplete]="autoRoom" class="form-input-full" autocomplete="off">
                <label for="room" class="form-label">{{ 'edit-subject-sheet.room' | translate }}</label>

                <mat-autocomplete #autoRoom="matAutocomplete">
                    @for (room of input.knownRooms; track $index) {
                        <mat-option [value]="room">
                            {{room}}
                        </mat-option>
                    }
                </mat-autocomplete>
            </div>

            <div class="form-item">
                <input type="text" formControlName="teacher" id="teacher" [matAutocomplete]="autoTeacher" class="form-input-full" autocomplete="off">
                <label for="teacher" class="form-label">{{ 'edit-subject-sheet.teacher' | translate }}</label>

                <mat-autocomplete #autoTeacher="matAutocomplete">
                    @for (teacher of input.knownTeachers; track $index) {
                        <mat-option [value]="teacher">
                            {{teacher}}
                        </mat-option>
                    }
                </mat-autocomplete>
            </div>
        </div>

        <div class="btn-wrapper">
            <img style="filter: drop-shadow(3.5px 3.5px 3.5px #aaaaaa);" src="assets/images/delete-icon.png" alt="" height="44px" width="44px" (click)="deleteSubject()">

            <fam-button-cta [disabled]="!form.valid" [btnName]="'edit-subject-sheet.save' | translate"
                [icon]="faFloppyDisk" (click)="handleSubmit()"></fam-button-cta>
        </div>
        <br>
    </form>
</div>
