import { Injectable} from '@angular/core';
import { BehaviorSubject, catchError, combineLatest, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { HttpErrorService } from '../error/http-error.service';
import { HttpClient } from '@angular/common/http';
import { FamilyTricksSubscription } from './family-tricks-subscription.data';
import { environment } from 'src/environments/environment';
import { NewSubscription } from './new-subscription.data';
import { UpdatedSubscription } from './updated-subscription.data';
import { Purchase } from './purchase.model';
import { Transaction } from './transaction.model';
import { toSignal } from '@angular/core/rxjs-interop';
import { CachingService } from '../caching/caching.service';
import { UpdatedGeography } from './updated-geography.data';
import { OnboardedSubscription } from './onboarded-subscription.data';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private subscriptionUrl = `${environment.apiUri}/v1.0/familysubscriptions`;
  subscriptionModifiedSubject = new BehaviorSubject<void>(undefined);

  private subscription$ = combineLatest([
    this.cachingService.globalRefresh$,
    this.subscriptionModifiedSubject
  ]).pipe(
    switchMap(() => {
      return this.getSubscription();
    }),
    shareReplay(1)
  );
  subscription = toSignal(this.subscription$);

  constructor(
    private http: HttpClient,
    private httpErrorService: HttpErrorService,
    private cachingService: CachingService,
    private profileService: ProfileService
  ) { }

  getSubscription(): Observable<FamilyTricksSubscription> {
    return this.http.get<FamilyTricksSubscription>(this.subscriptionUrl);
  }

  addSubscription(newSubscription: NewSubscription): Observable<FamilyTricksSubscription> {
    return this.http.post<FamilyTricksSubscription>(this.subscriptionUrl, newSubscription).pipe(
      tap(value => {
        this.subscriptionModifiedSubject.next();
      }),
      catchError(this.httpErrorService.handleError)
    );
  }

  updateSubscription(subscription: FamilyTricksSubscription): Observable<FamilyTricksSubscription> {
    const updatedSubscription: UpdatedSubscription = {
      receiveNewsletter: subscription.receiveNewsletter
    };

    return this.http.put<FamilyTricksSubscription>(`${this.subscriptionUrl}/receivenewsletter`, updatedSubscription).pipe(
      tap(value => {
        this.subscriptionModifiedSubject.next();
      }),
      catchError(this.httpErrorService.handleError)
    );
  }

  updateGeography(updatedGeography: UpdatedGeography): Observable<any> {

    return this.http.put(`${this.subscriptionUrl}/UpdateGeography`, updatedGeography).pipe(
      tap(value => {
        this.subscriptionModifiedSubject.next();
      }),
      catchError(this.httpErrorService.handleError)
    );
  }

  onboardFamily(onboardedFamily: OnboardedSubscription): Observable<any> {

    return this.http.put(`${this.subscriptionUrl}/Onboard`, onboardedFamily).pipe(
      tap(value => {
        this.subscriptionModifiedSubject.next();
        this.profileService.profileModifiedSubject.next();
      }),
      catchError(this.httpErrorService.handleError)
    );
  }

  acknowledgePurchaseOnBackend(purchase: Purchase): Observable<boolean> {
    return this.http.put<FamilyTricksSubscription>(`${this.subscriptionUrl}/acknowledgepurchase`, purchase).pipe(
      tap(value => {
        this.subscriptionModifiedSubject.next();
      }),
      switchMap((subscription) => {
        if (subscription && subscription.activeSubscription) {
          return of(true);
        }
        return of(false);
      }),
      catchError(this.httpErrorService.handleError)
    );
  }

  verifyIosTransaction(transaction: Transaction): Observable<boolean> {
    return this.http.put<FamilyTricksSubscription>(`${this.subscriptionUrl}/verifytransaction`, transaction).pipe(
      tap(value => {
        this.subscriptionModifiedSubject.next();
      }),
      switchMap((subscription) => {
        if (subscription && subscription.activeSubscription) {
          return of(true);
        }
        return of(false);
      }),
      catchError(this.httpErrorService.handleError)
    );
  }
}
