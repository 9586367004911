import { NgModule } from '@angular/core';
import { LocaleService } from './services/locale/locale.service';
import { RouterModule } from '@angular/router';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { CanDeactivateGuard } from './guards/can-deactivate/can-deactivate.guard';
import { UnsavedChangesDialog } from './components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { HomePageComponent } from './home-page/home-page.component';
import { TweenHomeComponent } from './components/tween-home/tween-home.component';
import { AdultHomeComponent } from './components/adult-home/adult-home.component';
import { ChooseProfilePage } from './choose-profile-page/choose-profile-page.component';
import { KidsHomeComponent } from './components/kids-home/kids-home.component';
import { ChildrensTasksComponent } from './components/childrens-tasks/childrens-tasks.component';
import { DoneImgComponent } from './components/done-img/done-img.component';
import { SharedCoreModule } from '../shared-core/shared-core.module';
import { AddToDoSheet } from './components/add-to-do-sheet/add-to-do-sheet.component';
import { ChildrensEventsComponent } from './components/childrens-events/childrens-events.component';
import { UniversePageComponent } from './universe-page/universe-page.component';
import { ChildGreetingComponent } from './components/child-greeting/child-greeting.component';
import { TweenToDoToggleComponent } from './components/tween-to-do-toggle/tween-to-do-toggle.component';
import { TweenToDoComponent } from './components/tween-to-do/tween-to-do.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { SummerCampaignComponent } from './components/summer-campaign/summer-campaign.component';
import { LeafAnimationComponent } from './components/leaf-animation/leaf-animation.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { SchoolStartCampaignComponent } from './components/school-start-campaign/school-start-campaign.component';
import { HalloweenCampaignComponent } from './components/halloween-campaign/halloween-campaign.component';
import { ConsentBannerDialogComponent } from './components/consent-banner-dialog/consent-banner-dialog.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';

@NgModule({
  declarations: [
    ToolbarComponent,
    UnsavedChangesDialog,
    HomePageComponent,
    KidsHomeComponent,
    TweenHomeComponent,
    AdultHomeComponent,
    ChooseProfilePage,
    ChildrensTasksComponent,
    DoneImgComponent,
    ChildGreetingComponent,
    AddToDoSheet,
    ChildrensEventsComponent,
    UniversePageComponent,
    TweenToDoToggleComponent,
    TweenToDoComponent,
    LoginPageComponent,
    SummerCampaignComponent,
    LeafAnimationComponent,
    ErrorPageComponent,
    SchoolStartCampaignComponent,
    HalloweenCampaignComponent,
    ConsentBannerDialogComponent,
  ],
  providers: [LocaleService, CanDeactivateGuard],
  imports: [RouterModule, SharedCoreModule, CdkAccordionModule],
  exports: [ToolbarComponent, SharedCoreModule],
})
export class CoreModule {}
