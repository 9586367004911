 @if (isScreenSmall === true) {
  <div [ngClass]="[theme + 'mat-app-background']"> 
       <div [ngClass]="[isChildProfile() ? 'child-bg' : 'app-content']"> 
        <router-outlet></router-outlet>
        <app-loading></app-loading> 
      </div> 
      @if (showToolbar()) {
        <app-toolbar></app-toolbar>
      } 
  </div> 
} 
 @else {
  <div [ngClass]="[isChildProfile() ? 'children-bg' : 'adult-bg']">
    <div class="app-frame">
      <div [ngClass]="[theme + ' mat-app-background']">
          <div [ngClass]="[isChildProfile() ? 'child-bg' : 'app-content']">
              <router-outlet></router-outlet>
              <app-loading></app-loading>
          </div>
          @if (showToolbar()) {
            <app-toolbar></app-toolbar>
          }
      </div>
    </div>
  </div>
} 
