import {Injectable} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {Browser} from '@capacitor/browser';
import {Preferences} from '@capacitor/preferences';
import { callbackUri } from 'src/app/auth.config';
import { ProfileService } from '../profile/profile.service';
import { Router } from '@angular/router';

const returnTo = callbackUri;

@Injectable({providedIn: 'root'})
export class LogoutService {

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private router: Router
  ) {
  }

  logout() {
    this.profileService.logoutOfOneSignal();
    
    // clear profie and other app settings
    Preferences.clear().then(value => {

      // logout of auth0
      this.authService.logout({
        logoutParams: {
          returnTo,
        },
        async openUrl(url: string) {
          await Browser.open({ url });
        } 
      }).subscribe({
        next: () => {
          this.router.navigateByUrl(`login`);
        }
      });
    });
  }
}
