import { Component, computed, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditTimetableDialogComponent } from '../edit-timetable-dialog/edit-timetable-dialog.component';
import { SavedTimetableMessageDialogComponent } from '../saved-timetable-message-dialog/saved-timetable-message-dialog.component';
import { faFloppyDisk, faPlus, faPen } from '@fortawesome/pro-light-svg-icons';
import { faCircleNodes } from '@fortawesome/pro-duotone-svg-icons';
import { Timetable } from 'src/app/core/services/timetable/timetable.data';
import { ProfileService } from 'src/app/core/services/profile/profile.service';
import { TimetableService } from 'src/app/core/services/timetable/timetable.service';
import { AddSmartTasksDialogComponent } from '../add-smart-tasks-dialog/add-smart-tasks-dialog.component';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { EUserType } from 'src/app/core/services/profile/user-type.enum';
import { take } from 'rxjs';
import { UpsertedSmartTask } from 'src/app/core/services/to-do/upserted-smart-task.data';
import { ToDoService } from 'src/app/core/services/to-do/to-do.service';
import { ESmartLinkType } from 'src/app/core/services/to-do/smart-link-type.enum';
import { AddSmartTasksDialogInput } from '../add-smart-tasks-dialog/add-smart-tasks-dialog-input.model';
import { NewTimetable } from 'src/app/core/services/timetable/new-timetable.data';

@Component({
  selector: 'app-add-timetable-page',
  templateUrl: './add-timetable-page.component.html',
  styleUrl: './add-timetable-page.component.scss'
})
export class AddTimetablePageComponent implements OnInit {
  faFloppyDisk = faFloppyDisk;
  faPlus = faPlus;
  faCircleNodes = faCircleNodes;
  faPen = faPen;
  faCheck = faCheck

  form!: UntypedFormGroup;
  newTimetable: Timetable = {
    id: -1,
    profileId: -1,
    profileName: '',
    schoolSubjects: [],
    createPackingTaskId: null,
    createHomeworkTaskId: null
  };
  packingTask: UpsertedSmartTask | null = null;
  homeworkTask: UpsertedSmartTask | null = null;

  children = computed(() => {
    const timetables = this.timetableService.timetables();
    let children = this.profileService.profiles().filter(profile => profile.userType !== EUserType.Adult);

    if (timetables && timetables.length > 0) {
      children = children.filter(child => !timetables.some(t => t.profileId === child.id));
    }
    return children;
  });
  profilesError = this.profileService.profilesError;
  timetablesError = this.timetableService.timetablesError;

  activeToDos = computed(() => {
    return this.toDoService.toDos().filter(toDo => !this.toDoService.toDoIsDone(toDo));
  });
  activeToDosError = this.toDoService.toDosError;

  constructor(
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private profileService: ProfileService,
    private timetableService: TimetableService,
    private toDoService: ToDoService,
    public dialogRef: MatDialogRef<AddTimetablePageComponent>,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      child: [null, Validators.required]
    });
  }

  isTimetableReady() {
    return this.form.valid && this.newTimetable.schoolSubjects.length > 0;
  }

  navigateBack(): void {
    this.dialogRef.close();
  }

  submit() {
    this.newTimetable.profileId = this.form.controls.child.value;

    const addedTimetable: NewTimetable = {
      profileId: this.newTimetable.profileId,
      schoolSubjects: this.newTimetable.schoolSubjects,
      packingTask: this.packingTask,
      homeworkTask: this.homeworkTask
    };
    this.timetableService.addTimetable(addedTimetable).pipe(
      take(1)
    ).subscribe({
      next: result => {
        this.toDoService.toDoModifiedSubject.next();
      }
    });
    this.form.markAsPristine();

    const dialogRef = this.dialog.open(SavedTimetableMessageDialogComponent, {
      panelClass: 'app-full-bleed-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.navigateBack();
    });
  }

  navigateEditTimetable() {
    const dialogRef = this.dialog.open(EditTimetableDialogComponent, {
      panelClass: 'view-timetable-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false,
      data: this.newTimetable
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.newTimetable = result as Timetable;
      }
    });
  }

  navigateAddSmartTasks() {
    const profileId = this.form.controls.child.value;
    const profileToDos = this.activeToDos().filter(toDo => toDo.subscriberIds.some(id => id === profileId));

    const input: AddSmartTasksDialogInput = {
      showPackBag: this.packingTask === null,
      showHomework: this.homeworkTask === null,
      toDos: profileToDos,
      profileId: profileId
    };
    
    const dialogRef = this.dialog.open(AddSmartTasksDialogComponent, {
      panelClass: 'app-full-bleed-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false,
      data: input
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const upsertedSmartTask = result as UpsertedSmartTask;

        if (upsertedSmartTask.linkType === ESmartLinkType.PackBag) {
          this.packingTask = upsertedSmartTask;
        }
        else if (upsertedSmartTask.linkType === ESmartLinkType.Homework) {
          this.homeworkTask = upsertedSmartTask;
        }
      }
    });
  }

  removePackBagTask() {
    this.packingTask = null;
  }

  removeHomeworkTask() {
    this.homeworkTask = null;
  }
}
