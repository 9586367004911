import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Device } from '@capacitor/device';
import { Language } from './language.data';
import { catchError, from, map, of, take } from 'rxjs';

@Injectable({providedIn: 'root'})
export class LocaleService {

  supportedLangValues = ['en', 'da', 'de'];

  constructor(
    private translateService: TranslateService
  ) { }

  getSupportedLanguages() {
    let languages: Language[] = [
      { value: 'en', displayName: 'English' },
      { value: 'da', displayName: 'Dansk' },
      { value: 'de', displayName: 'Deutsch' },
    ];
    return languages;
  }

  setupLanguages() {
    // setup the translate service
    this.translateService.addLangs(this.supportedLangValues);
    this.translateService.setDefaultLang('en');

    // get the language from the device or default
    from(Device.getLanguageCode()).pipe(
      map((result) => {
        return result.value;
      }),
      take(1),
      catchError(() => of(this.translateService.defaultLang)),
    ).subscribe({
      next: (deviceLang) => {
        if (this.supportedLangValues.includes(deviceLang)) {
          this.setLanguage(deviceLang);
        }
      }
    });
  }

  setLanguage(language: string): void {
    this.translateService.use(language);
  }
}
