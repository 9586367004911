<div class="to-do-card-container" (click)="onToDoClicked()">
  <div class="to-do-card-content">
    @if (toDo.tasks[0].smartTask) { @if (toDo.tasks[0].smartTask.linkType ===
    ESmartLinkType.PackBag && !toDo.tasks[0].imageStr) {
    <div class="imgBoxSmart"></div>
    } @else if (toDo.tasks[0].smartTask.linkType === ESmartLinkType.Homework &&
    !toDo.tasks[0].imageStr) {
    <div class="imgBoxSmartHomework"></div>
    } } @else {
    <div
      class="imgBox"
      [ngStyle]="{ 'background-image': ' url(' + toDo.imageStr + ')' }"
    ></div>
    }
    <span>{{ toDo.name }}</span>
  </div>
</div>
<br />
