<section class="content-wrapper">

    <div style="display: flex; max-width: 100%; flex-wrap: nowrap; 
            align-items: center; margin-bottom: 16px;">
        <button class="go-back-btn" (click)="navigateBack()">
            <fa-icon class="arrow" [icon]="faArrowLeft" size="xl"></fa-icon>
        </button>
        <h2>{{ 'add-timetable.edit-timetable' | translate }}</h2>
    </div>

    <!-- Choose day -->
    <app-child-timetable-navigation [chosenDayOfWeek]="selectedDayOfWeek()" (nextEvent)="next()"
        (previousEvent)="previous()"></app-child-timetable-navigation>

    <!-- Timetable header -->
    <app-child-timetable-header></app-child-timetable-header>

    <!-- School subjects -->
    @if (selectedSubjects() && selectedSubjects().length > 0) {
    <ul>
        @for (subject of selectedSubjects(); track $index) {
        <li>
            <div class="time-box">
                <p>{{ subject.startHour | startTime : subject.startMinute }} - {{ subject.endHour | startTime :
                    subject.endMinute }}</p>
            </div>
            <div class="course-box">
                <div class="subject-icon-wrapper-top">
                    <p class="box1">{{ subject.name }}</p>
                    <fa-icon class="box2" [icon]="faPen" size="xl" (click)="editSubject(subject)"></fa-icon>
                </div>

                <div class="subject-icon-wrapper">
                    <p class="box3">{{ subject.room }}</p>
                    <p class="box4">{{ subject.teacher }}</p>
                </div>
            </div>
        </li>
        }
    </ul>
    }

    <div class="add-container">
        <button class="add-subject-btn" (click)="addSubjectToTimeTable()">
            <div class="text-icon-wrapper">
                <label class="body-text-kids-light"> {{ 'add-timetable.add-subject' | translate }}</label>
                <fa-icon class="arrow" [icon]="faPlus" size="xl"></fa-icon>
            </div>
        </button>
    </div>

    <!-- skal først på når første element er lagt på -->
    <div class="save-wrapper">
        <fam-button-cta [btnName]="'add-timetable.final-save' | translate" [icon]="faFloppyDisk"
            (buttonClickedEvent)="saveTimetable()"></fam-button-cta>
    </div>
</section>