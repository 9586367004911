import { Component, OnInit } from '@angular/core';
import { LogoutService } from '../services/logout/logout.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  contactLink!: string;

  constructor(
    private translateService: TranslateService,
    private logoutService: LogoutService
  ) { }

  ngOnInit(): void {
    this.translateService.get('error-page.contact').subscribe((translation: string) => {
      this.contactLink = translation;
    });
  }

  logout() {
    this.logoutService.logout();
  }
}
