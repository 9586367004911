<div class="dialog-wrapper" style="margin-bottom: 150px">
  <div class="img-wrapper">
    <img
      class="trick-img"
      src="../../../../assets/images/login-splashscreen.png"
      alt=""
    />
  </div>
  <p>
    {{ "consent-banner.intro-1" | translate }}
    <br /><br />

    {{ "consent-banner.intro-2" | translate }}

    <!-- TODO - use translations -->
    <a
      href="{{ 'consent-banner.legal-hub' | translate }}"
      target="_blank"
    >
      {{ "consent-banner.link-1" | translate }}</a
    >
    {{ "consent-banner.intro-3" | translate }}
  </p>

  <hr />

  <form class="form-styling" [formGroup]="form">
    <div class="toggle-wrapper">
      <p>{{ "consent-banner.necessary" | translate }}</p>
      <mat-slide-toggle [disabled]="true" [checked]="true"></mat-slide-toggle>
    </div>

    <div class="toggle-wrapper">
      <p>{{ "consent-banner.functional" | translate }}</p>
      <mat-slide-toggle formControlName="functionalCookies"></mat-slide-toggle>
    </div>
  </form>

  <hr />

  <div>
    <p class="title">{{ "consent-banner.title" | translate }}</p>

    <cdk-accordion class="example-accordion">
      <cdk-accordion-item
        #accordionItem1="cdkAccordionItem"
        class="example-accordion-item"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header1'"
        [attr.aria-expanded]="accordionItem1.expanded"
        [attr.aria-controls]="'accordion-body-'"
      >
        <div
          class="example-accordion-item-header"
          (click)="accordionItem1.toggle()"
        >
          <div class="header-title">
            {{ "consent-banner.necessary" | translate }}
          </div>

          @if (!accordionItem1.expanded) {
          <span class="example-accordion-item-description">
            <fa-icon
              style="color: #628395"
              [icon]="faArrowDown"
              size="2xl"
            ></fa-icon>
          </span>
          } @else {
          <span class="example-accordion-item-description">
            <fa-icon
              style="color: #628395"
              [icon]="faArrowUp"
              size="2xl"
            ></fa-icon>
          </span>
          }
        </div>

        <div
          class="example-accordion-item-body"
          role="region"
          [style.display]="accordionItem1.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-'"
          [attr.aria-labelledby]="'accordion-header-'"
        >
          <p class="body-text">
            {{ "consent-banner.necessary-text-1" | translate }}
            <br /><br />
            {{ "consent-banner.necessary-text-2" | translate }}
            <br /><br />
            {{ "consent-banner.necessary-text-3" | translate }}
          </p>
          <ul class="consent-list">
            <li class="consent-list-item">
              {{ "consent-banner.necessary-text-4" | translate }}
            </li>
            <li class="consent-list-item">
              {{ "consent-banner.necessary-text-5" | translate }}
            </li>
            <li class="consent-list-item">
              {{ "consent-banner.necessary-text-6" | translate }}
            </li>
            <li class="consent-list-item">
              {{ "consent-banner.necessary-text-7" | translate }}
            </li>
            <li class="consent-list-item">
              {{ "consent-banner.necessary-text-8" | translate }}
            </li>
          </ul>
        </div>
      </cdk-accordion-item>

      <cdk-accordion-item
        #accordionItem2="cdkAccordionItem"
        class="example-accordion-item"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-'"
        [attr.aria-expanded]="accordionItem2.expanded"
        [attr.aria-controls]="'accordion-body-'"
      >
        <div
          class="example-accordion-item-header"
          (click)="accordionItem2.toggle()"
        >
          <div class="header-title">
            {{ "consent-banner.functional" | translate }}
          </div>

          @if (!accordionItem2.expanded) {
          <span class="example-accordion-item-description">
            <fa-icon
              style="color: #628395"
              [icon]="faArrowDown"
              size="2xl"
            ></fa-icon>
          </span>
          } @else {
          <span class="example-accordion-item-description">
            <fa-icon
              style="color: #628395"
              [icon]="faArrowUp"
              size="2xl"
            ></fa-icon>
          </span>
          }
        </div>

        <div
          class="example-accordion-item-body"
          role="region"
          [style.display]="accordionItem2.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-'"
          [attr.aria-labelledby]="'accordion-header-'"
        >
          <p class="body-text">
            {{ "consent-banner.functional-text-1" | translate }}

            <br /><br />
            {{ "consent-banner.functional-text-2" | translate }}
            <br /><br />
            {{ "consent-banner.functional-text-3" | translate }}
          </p>
          <ul class="consent-list">
            <li class="consent-list-item">
              {{ "consent-banner.functional-text-4" | translate }}
            </li>
            <li class="consent-list-item">
              {{ "consent-banner.functional-text-5" | translate }}
            </li>
            <li class="consent-list-item">
              {{ "consent-banner.functional-text-6" | translate }}
            </li>
            <li class="consent-list-item">
              {{ "consent-banner.functional-text-7" | translate }}
            </li>
            <li class="consent-list-item">
              {{ "consent-banner.functional-text-8" | translate }}
            </li>
          </ul>
          <p>
            {{ "consent-banner.functional-text-9" | translate }}
            <br /><br />
            {{ "consent-banner.functional-text-10" | translate }}
          </p>
        </div>
      </cdk-accordion-item>
    </cdk-accordion>
  </div>
</div>
<footer>
  <button class="cta-btn" (click)="saveAll()">
    {{ "consent-banner.allow-all" | translate }}
  </button>

  <button class="cta-btn" (click)="saveChosen()">
    {{ "consent-banner.allow-chosen" | translate }}
  </button>
</footer>
